<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$sjglXjjlSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;" :dynamic='true' :noOperation="true"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <div class="bg-white" style="height: 100%;" id="table_box_height">
        <!-- 列表页 -->
        <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" @handleSelectionChange="handleSelectionChange" :isCbxx="true" :otherColumn="otherColumn">
          <template v-slot:imgSrc="row">
            <el-image ref="myImg"  v-if="row.rowData.routingInspectionImagePath" style="width: 24px; height: 24px;cursor: pointer;" :src="require('@/assets/img/thumbnail.png')" @click.stop="viewImg($event,row.rowData)"> </el-image>
            <!-- <img src="@/assets/img/thumbnail.png" alt="" class="Dosage_img" style="width: 24px; height: 24px;cursor: pointer;" @click="viewImg(row.rowData)"> -->
          </template>
        </basic-table >
      </div>
    </div>

    <el-dialog title="查看图片" :visible.sync="imgDialogVisible" width="30%" >
      <el-image :src="previewImgSrc" > </el-image>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import Sticky from '@/components/Sticky'
  export default {
    name: 'xcjl',//巡查记录
    components: {
      Sticky
    },
    props: {
      //设备类型
      // deviceType:[String, Object, Array],
      visible:{
        default:false
      },
      //是否是详情，详情进入不可修改
      isCheckDetail:{
        default:false
      },
    },
    directives: {
      
    },
    data() {
      return {
        loading:false,//遮罩
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          key:"",
          queryKey:'UserNumber',
          timer:[new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),new Date()],
          StartTime:'',
          EndTime:'',
        },
        // firstHeaderList:[],// 主列表列定义
        firstHeaderList:[// 主列表列定义
          {
            key :  "userName",
            comment :  "任务名称",
            description :  "任务名称",
          },
          {
            key :  "surfaceName",
            comment :  "巡查设备",
            description :  "巡查设备",
          },
          {
            key :  "userName",
            comment :  "巡查人员",
            description :  "巡查人员",
          },
          {
            key :  "address",
            comment :  "巡查地点",
            description :  "巡查地点",
          },
          {
            key :  "areaName",
            comment :  "巡查内容",
            description :  "巡查内容",
          },
          {
            key :  "currentUpdateTime",
            comment :  "巡查日期",
            description :  "巡查日期",
          },
        ],
        tableTotal:0,//明细条数
        chooseList:[],//多选数据
        tableData:[],
        tableHeight:500,//表格高度
        otherColumn:[//
          {
            label:'巡查图片',
            slotName:'imgSrc',
          }
        ],
        previewImgSrc:"",//预览图片列表
        imgDialogVisible:false,//图片弹窗
      }
    },
    watch: {
      
    },
    computed: {
      
    },
    filters: {
      
    },
    created() {
      
      this.getList()
    },
    mounted() {
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight - 50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight - 50
          this.$forceUpdate()
        });
        // 计算table高度
      })
    },
    methods: {
      // 获取数据
      getList() {
        var searchQuery = Object.assign({},this.listQuery)
        if(searchQuery.timer&&searchQuery.timer.length == 2){
          searchQuery.StartTime = this.$basicFun.dataFormat(searchQuery.timer[0],'yyyy-MM-dd hh:mm');
          searchQuery.EndTime = this.$basicFun.dataFormat(searchQuery.timer[1],'yyyy-MM-dd hh:mm');
        }
        this.loading = true
        basicDataApi.sjglXcjlSearch(searchQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      //多选
      handleSelectionChange(val){
        this.chooseList = val
      },
      handleDelete(row) { // 多行删除
        this.tableData.splice(this.tableData.findIndex(e=>e.serialNumber === row.serialNumber),1)
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        if(domId == 'btnAdd'){//新增
          this.FormDialog = true
          return
        }
        if(domId == 'btnEdit'){//编辑
          if(this.chooseList.length != 0){
            this.$message.error('请选择一个设备进行操作！');
            return;
          }
          this.FormDialog = true
          return
        }
        if(domId == 'btnDel'){//删除
          if(this.chooseList.length <= 0){
            this.$message.error('请选择需要删除的设备！');
            return;
          }
          this.$message.success('删除成功');
          return
        }
        
        if(domId == 'btnExport'){//导出
          this.handleDownExcel()
          return
        }
      },
      //点击图片
      viewImg(e,row){
        this.previewImgSrc =  process.env.VUE_APP_BASE_IMG_URL +"/File/"+row.routingInspectionImagePath
        this.imgDialogVisible = true
        console.log(e,33,this.previewImgSrc)
      },
    },
  }

</script>

<style lang="scss" scoped>

</style>
